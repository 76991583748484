import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { API } from "aws-amplify";
import { useQuery } from "@tanstack/react-query";

import { CustomError } from "utils/error";
import { useDebounce } from "hooks/debounce";
import { ProjectCategory as ProjectCategoryType } from "API";
import { useAlerts } from "contexts/alerts";
import { projectCategoriesByGroupId } from "graphql/queries";

interface ProjectProps {
  defaultProjectCategories?: ProjectCategoryType[];
  groupId: String | undefined;
}
export function ProjectCategory({
  groupId,
  defaultProjectCategories,
}: ProjectProps) {
  const { control } = useFormContext();
  const [inputText, setInputText] = useState<string>("");
  const debouncedInputText = useDebounce(inputText, 500);
  const { addAlert } = useAlerts();

  const { data: projectCategories = defaultProjectCategories, isLoading } =
    useQuery({
      queryKey: ["projectCategories", groupId, debouncedInputText],
      queryFn: async () => {
        let nextToken: string | null = null;
        let allItems: ProjectCategoryType[] = [];
        try {
          while (true) {
            const res: any = await API.graphql({
              query: projectCategoriesByGroupId,
              variables: {
                groupId,
                nextToken,
                filter: {
                  active: { ne: false },
                  name: { contains: debouncedInputText },
                },
              },
              authMode: "AMAZON_COGNITO_USER_POOLS",
            });
            const items = res.data.projectCategoriesByGroupId.items;
            allItems = allItems.concat(items);
            nextToken = res.data.projectCategoriesByGroupId.nextToken;
            if (!nextToken) break;
          }
          return allItems;
        } catch (err) {
          const customError = new CustomError(err, "get");
          addAlert({ message: customError.message, severity: "error" });
        }
      },
      staleTime: 1000 * 60 * 5,
      gcTime: 1000 * 60 * 6,
    });

  const options = projectCategories
    ?.map((p) => ({
      name: p.name,
      id: p.id,
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Controller
      name="categoryId"
      control={control}
      render={({ field }) => (
        <Autocomplete
          {...field}
          value={options?.find((option) => option.id === field.value) ?? null}
          onChange={(_, selectedOption) => {
            field.onChange(selectedOption?.id ?? null);
          }}
          onInputChange={(_, value) => {
            setInputText(value || "");
          }}
          options={options ?? []}
          isOptionEqualToValue={(option) => option.id === field.value}
          getOptionLabel={(opt) => opt.name ?? ""}
          renderOption={(props, option) => (
            <Box {...props} key={option.id} component="li">
              {option.name}
            </Box>
          )}
          loading={isLoading}
          renderInput={(params) => (
            <TextField
              {...params}
              label="案件分類"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
}
