import { API } from "aws-amplify";
import moment from "moment";

import { Schedule } from "API";
import { getProject } from "graphql/queries";
import { getProjectTypeName } from "utils/project";

export async function getProjectData(projectId: string) {
  const p = (
    await API.graphql({
      query: getProject,
      variables: { id: projectId },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
  ).data.getProject;

  let isExternalShareEnabled = false;
  if (
    p.externalShareExpirationDate &&
    p.externalShareEnabled &&
    p.externalSharePassword &&
    p.externalShareUrl
  ) {
    const isExpired = moment()
      .startOf("day")
      .isAfter(moment(p.externalShareExpirationDate));
    isExternalShareEnabled = !isExpired;
  }

  const project = {
    id: p.id,
    name: p.name || "-",
    type: getProjectTypeName(p.type),
    accountName: p.accountName || "-",
    contactName: p.contactName || "-",
    contactTitle: p.contactTitle || "-",
    reference: p.reference || "-",
    alert: p.alert || "",
    qrcode: "qrcode",
    userName: p.user.name || "-",
    managerName: p.managerName || "-",
    quoterName: p.quoterName || "-",
    operatorName: p.operatorName || "-",
    account: p.account.name || "-",
    externalShareUrl: isExternalShareEnabled ? p.externalShareUrl || "-" : "-",
    externalSharePassword: isExternalShareEnabled
      ? p.externalSharePassword || "-"
      : "-",
    externalShareEnabled: isExternalShareEnabled ? "TRUE" : "FALSE",
    externalShareExpirationDate: isExternalShareEnabled
      ? p.externalShareExpirationDate || "-"
      : "-",
    category: p.category?.name || "-",
  };

  const sortByCreatedDate = (a: Schedule, b: Schedule) => {
    return moment(a.createdAt).isBefore(b.createdAt) ? -1 : 1;
  };

  const ss = p.schedules.items;

  const schedules = ss
    .sort(sortByCreatedDate)
    .map((s: Schedule, idx: number) => ({
      number: idx + 1,
      id: s.id,
      name: s.name || "-",
      m3: s.m3 || "-",
      case: s.case || "-",
      shipType: s.shipType || "-",
      stockingDate: s.stockingDate || "-",
      packagingDate: s.packagingDate || "-",
      shippingDate: s.shippingDate || "-",
      cutDate: s.cutDate || "-",
    }));

  const g = p.group || {};

  const group = {
    name: g.name || "-",
    phone: g.phone || "-",
    fax: g.fax || "-",
    email: g.email || "-",
    taxRate: g.taxRate || "-",
  };

  const ga = p.group.groupAddress || {};

  const groupAddress = {
    postalCode: ga.postalCode || "-",
    country: ga.country || "-",
    state: ga.state || "-",
    city: ga.city || "-",
    street: ga.street || "-",
  };

  const gb = p.group.bank || {};

  const groupBank = {
    name: gb.name || "-",
    branchName: gb.branchName || "-",
    branchNumber: gb.branchNumber || "-",
    type: gb.type || "-",
    accountNumber: gb.accountNumber || "-",
  };

  const account = {
    name: p.accountName || "-",
    contactName: p.contactName || "-",
    contactTitle: p.contactTitle || "-",
    billingPostalCode: p.account.billingPostalCode || "-",
    billingState: p.account.billingState || "-",
    billingStreet: p.account.billingStreet || "-",
  };

  const data = {
    project,
    schedules,
    group,
    groupAddress,
    groupBank,
    account,
  };

  return data;
}
