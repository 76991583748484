import { API } from "aws-amplify";

import { productSummariesByProjectId } from "graphql/queries";
import {
  ProductSummariesFilter,
  sortSummariesBySummaryOrder,
} from "utils/productSummary";

export async function getProductSummaryData(
  projectId: string,
  filter: ProductSummariesFilter | undefined,
  summaryOrderMapping: { [key: string]: number } | undefined = undefined
) {
  const p = (
    await API.graphql({
      query: productSummariesByProjectId,
      variables: {
        input: { projectId, filter },
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
  ).data.productSummariesByProjectId.items;

  if (summaryOrderMapping === undefined) {
    p.sort(
      // ここのsortの部分を持ってきた順番の物を使うようにする
      (a: any, b: any) => {
        if (a.order !== b.order) {
          return a.order - b.order;
        }

        return 0;
      }
    );
  } else {
    p.map((productSummary: any) => {
      const productIdOrderPair = `${productSummary.productId}|${productSummary.caseOrder}`;
      productSummary.number = summaryOrderMapping[productIdOrderPair];
      productSummary.summaryOrder = summaryOrderMapping[productIdOrderPair];
      return productSummary;
    });
    sortSummariesBySummaryOrder(p);
  }

  const summaryLines = p.map((s: any, idx: number) => {
    return {
      ...s,
      // numberは表示順の通し番号にしたいのでここで上書きする(そのままだとデフォルトの9999とかが入っちゃう)
      number: idx + 1,
      phaseName: s.phaseName === "complete" ? "完了" : s.phaseName,
    };
  });

  const summary = p.reduce(
    (r: any, s: any) => {
      r.totalQuantity += s.quantity || 0;
      r.totalNetWeight += s.netWeight || 0;
      r.totalGrossWeight += s.grossWeight || 0;
      r.totalM3 += s.m3 || 0;

      return r;
    },
    {
      totalQuantity: 0,
      totalNetWeight: 0,
      totalGrossWeight: 0,
      totalM3: 0,
    }
  );

  const data = {
    summary,
    summaryLines,
  };

  return data;
}
