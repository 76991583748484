import { API } from "aws-amplify";

import { CustomError } from "./error";

import { Case } from "API";
import { GraphQLInput } from "hooks/data";
import * as mutations from "graphql/mutations";

export const getPhaseIdFromStepIndex = (c: Case, index: number) => {
  if (!c || !c.packPhases) return null;

  if (index === c.packPhases.length) return "final";
  if (index > c.packPhases.length) return "complete";

  const p = c.packPhases[index];
  if (!p) return null;

  return p.id;
};

export const getStepIndex = (c: Case | undefined, phaseId: string) => {
  if (!c || !c.packPhases) return 0;
  if (phaseId === "final") return c.packPhases.length;
  if (phaseId === "complete") return c.packPhases.length + 1;
  const p = c.packPhases.findIndex((p) => p?.id === phaseId);
  if (p < 0) return 0;
  return p;
};

export const updatePackPhaseWithOptimisticLock = async (
  input: GraphQLInput
) => {
  try {
    const res = await API.graphql({
      query: mutations.updatePackPhaseWithOptimisticLock,
      variables: {
        input,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    if (res.errors) {
      throw new Error(res.errors);
    }

    return res.data.updatePackPhaseWithOptimisticLock;
  } catch (err) {
    throw new CustomError(err, "update");
  }
};

export const updateGroupWithOptimisticLock = async (input: GraphQLInput) => {
  try {
    const res = await API.graphql({
      query: mutations.updateGroupWithOptimisticLock,
      variables: {
        input,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    if (res.errors) {
      throw new Error(res.errors);
    }

    return res.data.updateGroupWithOptimisticLock;
  } catch (err) {
    throw new CustomError(err, "update");
  }
};
