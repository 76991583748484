import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useAuth } from "./auth";
import { useAlerts } from "./alerts";

import type { Group, PackPhase, UsersGroups } from "API";
import useData, { GraphQLInput } from "hooks/data";
import {
  updateGroupWithOptimisticLock,
  updatePackPhaseWithOptimisticLock,
} from "utils/packPhase";
import { CustomError } from "utils/error";

interface GroupContextValue {
  loading: boolean;
  group: Group | undefined;
  users: UsersGroups[] | [];
  update: (input: GraphQLInput) => void;
  updateGroupWithOptimisticLock: (input: GraphQLInput) => void;
  updatePackPhaseWithOptimisticLock: (input: GraphQLInput) => void;
  refetch: () => Promise<void>;
  set: (input: GraphQLInput) => void;
}

interface GroupContextProps {
  caseOrder?: string | null | undefined;
  children: ReactNode;
}

const GroupContext = createContext<GroupContextValue>({
  group: undefined,
  users: [],
  loading: false,
  update: () => null,
  updateGroupWithOptimisticLock: () => null,
  updatePackPhaseWithOptimisticLock: () => null,
  refetch: () => Promise.resolve(),
  set: () => Promise.resolve,
});

export const GroupProvider = ({ children }: GroupContextProps) => {
  const { currentGroup } = useAuth();
  const { addAlert } = useAlerts();
  const [users, setUsers] = useState<UsersGroups[]>([]);
  const { data, loading, refetch, update, set } = useData({
    object: "group",
    variables: {
      id: currentGroup?.id,
    },
  });

  useEffect(() => {
    refetch({
      id: currentGroup?.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGroup]);
  useEffect(() => {
    if (!data) return setUsers([]);
    setUsers(data.users.items);
  }, [data]);

  const setPackPhase = (input: GraphQLInput) => {
    const newPackPhases = [...data.packPhases];
    const index = data.packPhases.findIndex(
      (p: PackPhase) => p.id === input.packPhase.id
    );
    newPackPhases[index] = input.packPhase;
    const newGroup = {
      ...data,
      packPhases: newPackPhases,
    };
    set(newGroup);
  };

  const updateGroup = async (input: GraphQLInput) => {
    try {
      await updateGroupWithOptimisticLock(input);
      set({
        ...data,
        packPhases: input.packPhases,
        version: input.version + 1,
      });
      addAlert({ message: "更新しました", severity: "success" });
    } catch (err) {
      if (err instanceof CustomError)
        addAlert({ message: err.message, severity: "error" });
    }
  };

  const updatePackPhase = async (input: GraphQLInput) => {
    try {
      await updatePackPhaseWithOptimisticLock(input);
      input.packPhase.version = input.packPhase.version + 1;
      setPackPhase({ ...input });
      addAlert({ message: "更新しました", severity: "success" });
    } catch (err) {
      if (err instanceof CustomError)
        addAlert({ message: err.message, severity: "error" });
    }
  };

  const values = useMemo(
    () => ({
      group: data,
      users,
      update,
      updateGroupWithOptimisticLock: updateGroup,
      updatePackPhaseWithOptimisticLock: updatePackPhase,
      loading,
      refetch: () => refetch({ id: currentGroup?.id }),
      set,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      data,
      update,
      loading,
      users,
      set,
      updateGroupWithOptimisticLock,
      updatePackPhaseWithOptimisticLock,
    ]
  );

  return (
    <GroupContext.Provider value={values}>{children}</GroupContext.Provider>
  );
};

export const useGroup = () => {
  const groupContext = useContext(GroupContext);

  if (groupContext === undefined) {
    throw new Error("useGroup must be within GroupProvider");
  }

  return groupContext;
};
