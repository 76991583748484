import Link from "next/link";
import IconButton from "@mui/material/IconButton";
import HomeIcon from "@mui/icons-material/Home";
import Tooltip from "@mui/material/Tooltip";

import { useProject } from "contexts/project";
import { AppBarIconButton } from "layouts/AppBar/AppBarIconButton";

interface AppBarProps {
  open?: boolean;
  toggleDrawer: () => void;
}

export function ProjectAppBar({ toggleDrawer }: AppBarProps) {
  const { project } = useProject();

  return (
    <>
      <AppBarIconButton toggleDrawer={toggleDrawer} projectName={project?.name}>
        <Tooltip title="ホーム">
          <Link href="/" prefetch={false}>
            <IconButton color="inherit">
              <HomeIcon />
            </IconButton>
          </Link>
        </Tooltip>
      </AppBarIconButton>
    </>
  );
}
