/* eslint-disable react-hooks/exhaustive-deps */
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { uuid } from "short-uuid";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";
import Link from "next/link";
import { Button } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { API } from "aws-amplify";
import _ from "lodash";

import * as mutations from "graphql/mutations";
import type {
  GenerateExternalShareInfoInput,
  GenerateExternalShareInfoOutput,
  Project,
  ProjectPhotoPhase,
} from "API";
import useData, { GraphQLInput } from "hooks/data";
import { useDialog } from "contexts/dialog";
import { useAlerts } from "contexts/alerts";
import { CognitoUser, useAuth } from "contexts/auth";
import { removeSizePrefix } from "utils/image";
import { updateProjectWithOptimisticLock as updateWithOptimisticLock } from "utils/project";
import { CustomError } from "utils/error";

type AccessLevel = null | "quoter" | "operator" | "admin";

interface PhaseInput {
  [key: string]: any;
}

interface ProjectContextValue {
  loading: boolean;
  access: AccessLevel;
  project: Project | undefined;
  update: (input: GraphQLInput) => Promise<void>;
  updateProjectWithOptimisticLock: (input: GraphQLInput) => Promise<void>;
  updatePhotoPhases: (
    type: "delete" | "update" | "create",
    input: PhaseInput
  ) => Promise<void | string>;
  updateExternalSharedImageKeys: (
    shareImageKeys: string[],
    excludeImageKeys: string[]
  ) => Promise<void>;
  remove: () => Promise<void>;
  refetch: (variables: any) => Promise<void>;
  generateExternalInfo: () => Promise<void>;
  isShared: boolean;
}

interface ProjectContextProps {
  id: string | string[] | undefined;
  children: ReactNode;
}

function getAccessLevel(
  user: CognitoUser | undefined,
  project: Project | null | undefined
): AccessLevel {
  if (!user || !project) return null;

  // 案件作成者
  if (project.userId === user.id) return "admin";

  // 案件管理者
  if (project.managerId === user.id) return "admin";

  // 見積担当者
  if (project.quoterId === user.id) return "quoter";

  // 業務担当者
  if (project.operatorId === user.id) return "operator";

  return null;
}

const ProjectContext = createContext<ProjectContextValue>({
  project: undefined,
  loading: false,
  access: null,
  update: () => Promise.resolve(),
  updateProjectWithOptimisticLock: () => Promise.resolve(),
  updatePhotoPhases: () => Promise.resolve(),
  updateExternalSharedImageKeys: () => Promise.resolve(),
  remove: () => Promise.resolve(),
  refetch: () => Promise.resolve(),
  generateExternalInfo: () => Promise.resolve(),
  isShared: false,
});

export const ProjectProvider = ({ id, children }: ProjectContextProps) => {
  const { user, currentGroup } = useAuth();
  const [access, setAccess] = useState<AccessLevel>(null);
  const { data, loading, refetch, update, remove, set } = useData({
    object: "project",
    variables: { id },
  });
  const { open } = useDialog();
  const { addAlert } = useAlerts();
  const queryClient = useQueryClient();
  const isShared =
    data?.externalShareUrl != null &&
    data?.externalSharePassword != null &&
    data?.externalShareEnabled;

  // 外部公開ページの場合のuser/dataが空の状態でここに辿り着き、エラーになるのでNoCheckを利用
  useDeepCompareEffectNoCheck(() => {
    setAccess(getAccessLevel(user, data));
  }, [user, data]);

  useEffect(() => {
    refetch({
      id,
    });
  }, [id]);

  const removeProject = async () => {
    await remove({ id: id as string });
    queryClient.invalidateQueries({
      queryKey: ["projectsByGroupIdForManager"],
    });
  };

  const updateProject = async (input: GraphQLInput) => {
    await update(input);
    queryClient.invalidateQueries({
      queryKey: ["projectsByGroupIdForManager"],
    });
  };

  const updateProjectWithOptimisticLock = async (input: GraphQLInput) => {
    try {
      await updateWithOptimisticLock(input);
      queryClient.invalidateQueries({
        queryKey: ["projectsByGroupIdForManager"],
      });
      set({ ...data, ...input, version: input.version + 1 });
      addAlert({ message: "更新しました", severity: "success" });
    } catch (err) {
      if (err instanceof CustomError)
        addAlert({ message: err.message, severity: "error" });
    }
  };

  const updatePhotoPhases = async (
    type: "delete" | "update" | "create",
    input: PhaseInput
  ) => {
    let photoPhases = data.photoPhases?.slice() || [];
    // for delete and update type
    // find phaseId in product.photoPhases, error if not found
    const phaseExists =
      photoPhases.filter((p: ProjectPhotoPhase) => p.id === input.id).length ===
      1;
    if (!phaseExists && type !== "create")
      return addAlert({
        message: "梱包フェーズが見つかりません",
        severity: "error",
      });
    // if delete, remove phase
    if (type === "delete") {
      return open({
        title: "写真フェーズを削除しますか？",
        content: "保存された写真は復元できません。",
        okText: "削除する",
        onOk: async () => {
          await updateProject({
            id: data.id,
            photoPhases: photoPhases.filter(
              (p: ProjectPhotoPhase) => p.id !== input.id
            ),
          });
        },
      });
    }
    // if update, find and update
    if (type === "update") {
      photoPhases = photoPhases.reduce(
        (result: ProjectPhotoPhase[], p: ProjectPhotoPhase) => {
          if (p.id !== input.id) result.push(p);
          if (p.id === input.id) {
            result.push({
              ...p,
              ...input,
            });
          }
          return result;
        },
        []
      );
    }
    // if type is create, create with position input.index
    if (type === "create") {
      const index = input.index;
      delete input.index;
      photoPhases.splice(index, 0, { ...input, id: uuid() });
    }
    // update product.photoPhases
    await updateProject({
      id: data.id,
      photoPhases,
    });
  };

  // check if user has access to the same group as the project
  const userHasAccess = user?.groups?.find(
    (group) => group.id === data?.groupId
  );

  if (!userHasAccess && !!user && !!data?.groupId) {
    return (
      <div
        role="alert"
        className="w-full mt-10 flex flex-col justify-center items-center"
      >
        <h1 className="text-3xl font-bold mb-3">アクセスエラー</h1>
        <p className="mb-4">この案件を閲覧する権限がありません</p>
        <Link href="/">
          <Button variant="outlined">ホームへ戻る</Button>
        </Link>
      </div>
    );
  }

  // 外部共有用のURLとパスワードを発行する
  const generateExternalInfo = async () => {
    if (!data.id) return;
    const input: GenerateExternalShareInfoInput = {
      projectId: data.id,
    };
    const result: GenerateExternalShareInfoOutput = (
      await API.graphql({
        query: mutations.generateExternalShareInfo,
        variables: { input },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })
    ).data.generateExternalShareInfo;
    if (result.externalSharePassword && result.externalShareUrl) {
      await refetch({ id }, { progress: false });
      addAlert({
        message: "外部共有用のURLとパスワードを発行しました",
        severity: "success",
      });
      return;
    }
    addAlert({
      message: "案件の外部共有でエラーが発生しました",
      severity: "error",
    });
  };

  // 外部共有用の画像キーを更新する
  const updateExternalSharedImageKeys = async (
    shareImageKeys: string[],
    excludeImageKeys: string[]
  ) => {
    if (!data?.id) return;
    // 現状の共有された画像キー
    const alreadySharedImageKeys = data?.externalSharedImageKeys ?? [];
    // size prefixを削除しておく
    const addKeys = shareImageKeys.map((key) => removeSizePrefix(key));
    const delKeys = excludeImageKeys.map((key) => removeSizePrefix(key));
    // 追加→削除する
    const newSharedImages = _.difference(addKeys, alreadySharedImageKeys);
    const addedImageKeys = [...newSharedImages, ...alreadySharedImageKeys];
    const removedImageKeys = _.difference(addedImageKeys, delKeys);
    return await update({
      id: data?.id,
      externalSharedImageKeys: removedImageKeys,
    });
  };

  return (
    <ProjectContext.Provider
      value={{
        project: data,
        loading,
        update: updateProject,
        updateProjectWithOptimisticLock,
        remove: removeProject,
        access,
        updatePhotoPhases,
        updateExternalSharedImageKeys,
        refetch: async (customVariables) =>
          await refetch({
            groupId: currentGroup?.id,
            ...customVariables,
          }),
        generateExternalInfo,
        isShared,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export const useProject = () => {
  const projectContext = useContext(ProjectContext);

  if (projectContext === undefined) {
    throw new Error("useProject must be within ProjectsProvider");
  }

  return projectContext;
};
