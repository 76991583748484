import { QueryKey, useQuery } from "@tanstack/react-query";
import { API } from "aws-amplify";

import { useAlerts } from "contexts/alerts";
import { CustomError } from "utils/error";

interface APIResponse {
  __typename: string;
}

function extractQueryName(graphqlQuery: string) {
  const queryNameMatch = graphqlQuery.match(/query\s+([a-zA-Z0-9_]+)/);
  if (!queryNameMatch) return null;

  const queryName = queryNameMatch[1];
  // 頭文字を小文字に変換
  return queryName.charAt(0).toLowerCase() + queryName.slice(1);
}

const fetchAppSync = async <T extends APIResponse>(
  query: string,
  params: { [key: string]: unknown }
): Promise<T> => {
  const res = await API.graphql({
    query,
    variables: params,
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });

  const queryName = extractQueryName(query);

  if (!queryName) {
    throw new Error("Query name not found");
  }
  return res.data[queryName];
};

export const useQueryWrap = <T extends APIResponse, U>(
  queryKey: QueryKey,
  query: string,
  params: { [key: string]: unknown },
  queryFn: (res: T) => U,
  scaleTime?: number,
  gcTime?: number
) => {
  const { addAlert } = useAlerts();
  return useQuery({
    queryKey,
    queryFn: async () => {
      try {
        const data = await fetchAppSync<T>(query, params);

        return queryFn(data);
      } catch (err) {
        const customError = new CustomError(err, "get");
        if (customError instanceof Error)
          addAlert({ message: customError.message, severity: "error" });
      }
    },
    staleTime: scaleTime ?? 1000 * 60 * 5,
    gcTime: gcTime ?? 1000 * 60 * 6,
  });
};
