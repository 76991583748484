import { useRouter } from "next/router";
import IconButton from "@mui/material/IconButton";
import SettingIcon from "@mui/icons-material/Settings";
import Tooltip from "@mui/material/Tooltip";

import { useAuth } from "contexts/auth";
import { AppBarIconButton } from "layouts/AppBar/AppBarIconButton";

interface AppBarProps {
  open?: boolean;
  toggleDrawer: () => void;
}

export function HomeAppBar({ toggleDrawer }: AppBarProps) {
  const { currentGroup, user } = useAuth();
  const router = useRouter();
  const { permissions } = useAuth();

  if (!currentGroup)
    return (
      <div className="h-screen flex justify-center items-center">
        グループに所属されていません
      </div>
    );

  return (
    <>
      <AppBarIconButton toggleDrawer={toggleDrawer} title={currentGroup?.name}>
        {permissions.GroupInfo.canRead && (
          <Tooltip title="事業所設定">
            <IconButton
              aria-label="Group Setting"
              color="inherit"
              onClick={() => router.push(`/group/settings`)}
            >
              <SettingIcon />
            </IconButton>
          </Tooltip>
        )}
      </AppBarIconButton>
    </>
  );
}
