import { useForm, FormProvider } from "react-hook-form";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";

import Name from "./Name";
import Alert from "./Alert";
import Account from "./Account";
import Contact from "./Contact";
import Project from "./Project";
import ProjectType from "./ProjectType";
import PhotoPhases from "./PhotoPhases";
import { ProjectCategory } from "./ProjectCategory";

import { useAccounts } from "contexts/accounts";
import { useProjects } from "contexts/projects";
import Form from "components/Form";
import { useAuth } from "contexts/auth";
import { RedirectTypes } from "utils/project";
import { Project as ProjectT } from "API";

interface NewProjectProps {
  open: boolean;
  setOpen: (o: boolean) => void;
  defaultValues?: any;
  projects?: ProjectT[];
}

export function NewProject({
  open,
  setOpen,
  defaultValues,
  projects,
}: NewProjectProps) {
  const { create, copy } = useProjects();
  const { refetch: refetchAccounts } = useAccounts();
  const { user, currentGroup } = useAuth();
  const { handleSubmit, reset, ...method } = useForm({
    defaultValues,
  });

  useEffect(() => {
    reset();
    refetchAccounts({
      tenantId: user?.tenantId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onSubmit = (data: any, event: any) => {
    const redirectType = event.target.name;
    if (data.projectId) {
      // コピーの場合以下三つは使わないので削除
      if (data.pastProjectId || data.pastProjectId === "")
        delete data.pastProjectId;
      if (data.photoPhases) delete data.photoPhases;
      copy(data, redirectType);
    } else {
      create(data, redirectType);
    }
    setOpen(false);
  };

  return (
    <FormProvider {...method} reset={reset} handleSubmit={handleSubmit}>
      <Form
        open={open}
        setOpen={setOpen}
        title="新規案件"
        onSave={handleSubmit(onSubmit)}
        redirectConfig={{
          type: RedirectTypes.schedules,
          text: "保存してスケジュール作成",
        }}
      >
        <div className="m-4 w-full flex justify-center">
          <div className="w-full max-w-md flex flex-col gap-6">
            <Name />
            <Account />
            <Contact />
            <ProjectCategory groupId={currentGroup?.id} />
            <ProjectType />
            <Project defaultProjects={projects} groupId={currentGroup?.id} />
            <Alert />
            <div className="border border-1 border-gray-300 rounded p-4">
              <Typography variant="subtitle2" color="black">
                案件写真フェーズ
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ fontSize: 10, mb: 4 }}
                color="gray"
              >
                案件単位で写真を取れます
              </Typography>
              <PhotoPhases />
            </div>
          </div>
        </div>
      </Form>
    </FormProvider>
  );
}
