import { API } from "aws-amplify";

import { getMaterial, getValue } from "../elements";

import { Area, Case, DecorationComponent, Component, PackPhase } from "API";
import { materialsByGroupId } from "graphql/queries";
import { getAllProduct } from "graphql/product";
import { dateStringToJaDateTimeStr } from "utils/date";
import { calcM3 } from "utils/product";

export async function getProductData(productId: string, groupId: string) {
  const M2_TO_CM2 = 10_000; //PAX-622 定数に修正
  const M3_TO_CM3 = 10_000;

  const p = (
    await API.graphql({
      query: getAllProduct,
      variables: { id: productId },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
  ).data.getProduct;

  const as = p.areas.items;

  const ms = (
    await API.graphql({
      query: materialsByGroupId,
      variables: {
        groupId,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
  ).data.materialsByGroupId.items;

  const e = p.elements;

  const packPhases = !p.packPhases
    ? []
    : p.packPhases
        .filter((p: PackPhase) => !!p.showInKanBan)
        .map((p: PackPhase) => p.name)
        .join(", ");

  if (!p.schedule) {
    throw new Error("スケジュールが設定されていないため、帳票を出力できません");
  }

  const product = {
    id: p.id,
    productNumber: p.productNumber || "-",
    name: p.name || "-",
    packageTypeName: p.packageTypeName || "-",
    material: p.material || "",
    destination: p.destination || "-",
    stamp: p.stamp || "",
    description: p.description || "-",
    quantity: p.quantity || "-",
    productLength: getValue(e, "productLength") || "-",
    productWidth: getValue(e, "productWidth") || "-",
    productHeight: getValue(e, "productHeight") || "-",
    innerLength: getValue(e, "innerLength") || "-",
    innerWidth: getValue(e, "innerWidth") || "-",
    innerHeight: getValue(e, "innerHeight") || "-",
    outerLength: getValue(e, "outerLength") || "-",
    outerWidth: getValue(e, "outerWidth") || "-",
    outerHeight: getValue(e, "outerHeight") || "-",
    m3:
      calcM3(
        isNaN(getValue(e, "outerLength")) ? 0 : getValue(e, "outerLength"),
        isNaN(getValue(e, "outerWidth")) ? 0 : getValue(e, "outerWidth"),
        isNaN(getValue(e, "outerHeight")) ? 0 : getValue(e, "outerHeight")
      ) || "-",
    NW: getValue(e, "NW") || "-",
    clearanceLengthLeft: getValue(e, "clearanceLengthLeft") || "-",
    clearanceLengthRight: getValue(e, "clearanceLengthRight") || "-",
    clearanceWidthLeft: getValue(e, "clearanceWidthLeft") || "-",
    clearanceWidthRight: getValue(e, "clearanceWidthRight") || "-",
    clearanceHeightTop: getValue(e, "clearanceHeightTop") || "-",
    clearanceHeightTopUnderBeam:
      getValue(e, "clearanceHeightTopUnderBeam") || "-",
    clearanceHeightBottom: getValue(e, "clearanceHeightBottom") || "-",
    verticalClearance: getValue(e, "verticalClearance") || "-",
    grossWeight: p.grossWeight || "-",
    panelM2: Math.round(p.panelM2 * M2_TO_CM2) / M2_TO_CM2 || "-", //PAX-622 10倍になっていたので修正
    woodM3: Math.round(p.woodM3 * M3_TO_CM3) / M3_TO_CM3 || "-",
    note: p.note || "-",
    tareWeight: getValue(e, "tareWeight") || p.tareWeight || "-",
    stockingDate: p.schedule.stockingDate || "-",
    packagingDate: p.schedule.packagingDate || "-",
    shippingDate: p.schedule.shippingDate || "-",
    shipType: p.schedule.shipType || "-",
    packPhases,
    createdAt: p.createdAt ? dateStringToJaDateTimeStr(p.createdAt) : "-",
    updatedAt: p.updatedAt ? dateStringToJaDateTimeStr(p.updatedAt) : "-",
  };

  const components = as
    .sort((a: any, b: any) => a.order - b.order)
    .reduce((r: any, area: Area) => {
      const cs = area.components?.items
        .sort((a: any, b: any) => a.order - b.order)
        .map((c: Component | null) => {
          return {
            areaName: area.name,
            name: c?.name,
            order: c?.order,
            material: getMaterial(ms, c?.elements) || "-",
            length: getValue(c?.elements, "length"),
            width: getValue(c?.elements, "width"),
            height: getValue(c?.elements, "height"),
            quantity: getValue(c?.elements, "quantity"),
            m2: getValue(c?.elements, "m2"),
            m3: getValue(c?.elements, "m3"),
            lengthNominal: getValue(c?.elements, "lengthNominal"),
            widthNominal: getValue(c?.elements, "widthNominal"),
            heightNominal: getValue(c?.elements, "heightNominal"),
            notes: getValue(c?.elements, "notes") || "",
            createdAt: c?.createdAt
              ? dateStringToJaDateTimeStr(c?.createdAt)
              : "",
            updatedAt: c?.updatedAt
              ? dateStringToJaDateTimeStr(c?.updatedAt)
              : "",
          };
        });
      return r.concat(cs);
    }, []);

  const is = p.decorations.items.filter(
    (d: DecorationComponent) => d.type === "INTERIOR"
  );
  const es = p.decorations.items.filter(
    (d: DecorationComponent) => d.type === "EXTERIOR"
  );

  const interiors = is.map((i: DecorationComponent) => ({
    name: i.name,
    order: i.order,
    material: getMaterial(ms, i.elements),
    quantity: getValue(i.elements, "quantity"),
    createdAt: i.createdAt ? dateStringToJaDateTimeStr(i.createdAt) : "",
    updatedAt: i.updatedAt ? dateStringToJaDateTimeStr(i.updatedAt) : "",
  }));

  const exteriors = es.map((e: DecorationComponent) => ({
    name: e.name,
    order: e.order,
    material: getMaterial(ms, e.elements),
    quantity: getValue(e.elements, "quantity"),
    createdAt: e.createdAt ? dateStringToJaDateTimeStr(e.createdAt) : "",
    updatedAt: e.updatedAt ? dateStringToJaDateTimeStr(e.updatedAt) : "",
  }));

  const cs = p.cases;

  const cases = cs.map((c: Case) => ({
    name: c.name || `${p.name}-${c.order}`,
    order: c.order,
    outerLength: c.outerLength,
    outerWidth: c.outerWidth,
    outerHeight: c.outerHeight,
    netWeight: c.netWeight,
    grossWeight: c.grossWeight,
    note: c.note,
    packPhases,
  }));

  const data = {
    product,
    interiors,
    exteriors,
    cases,
    components,
  };

  return data;
}
