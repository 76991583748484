import { GridRowSelectionModel } from "@mui/x-data-grid";
import moment from "moment";

import {
  Group,
  InvoiceLine,
  Product,
  ProductCaseInput,
  Project,
  Quote,
} from "API";
import { GraphQLInput } from "hooks/datalist";
import InvoiceErrorMessages from "utils/errors/invoice";
import { ProductSummary } from "utils/productSummary";

type CreateInvoiceInput = {
  name: string;
  invoiceNo: string;
  issueDate: string;
  billingDate: string;
  recordingDate: string;
};

export const getInvoiceInputs = (
  group: Group,
  project: Project,
  userId: string,
  input: CreateInvoiceInput,
  domain: string
): GraphQLInput => {
  return {
    projectId: project.id,
    accountId: project.accountId,
    contactId: project.contactId,
    userId: userId,
    groupId: group.id,
    name: input.name,
    invoiceNo: input.invoiceNo,
    issueDate: moment(input.issueDate).isValid() ? input.issueDate : null,
    billed: false,
    billingDate: moment(input.billingDate).isValid() ? input.billingDate : null,
    recordingDate: moment(input.recordingDate).isValid()
      ? input.recordingDate
      : null,
    description: "",
    subtotal: 0,
    nonTaxableTotal: 0,
    taxableSubtotal: 0,
    taxTotal: 0,
    taxableTotal: 0,
    total: 0,
    accountName: project.account?.name,
    accountSite: project.account?.site,
    billingType: project.account?.billingType ?? null,
    accountAddress: {
      address: project.account?.billingAddress ?? null,
      street: project.account?.billingStreet ?? null,
      city: project.account?.billingCity ?? null,
      state: project.account?.billingState ?? null,
      postalCode: project.account?.billingPostalCode ?? null,
      country: project.account?.billingCountry ?? null,
    },
    contactName: `${project.contact?.lastName} ${
      project.contact?.firstName ?? ""
    }`,
    contactFirstName: project.contact?.firstName ?? "",
    contactLastName: project.contact?.lastName,
    contactDepartment: project.contact?.department ?? "",
    contactTitle: project.contact?.title ?? "",
    groupName: group.name,
    logoUrl: group.logoUrl,
    groupBank: group.bank,
    groupAddress: group.groupAddress,
    phone: group.phone,
    fax: group.fax,
    taxRate: group.taxRate,
    url:
      domain !== ""
        ? `https://${domain}/projects/${project.id}`
        : `https://pax-cloud.com/projects/${project.id}`,
  };
};

export const canImportProductAndQuote = (
  allProducts: Product[],
  targetProductIds: string[],
  project: Project,
  quotes: Quote[]
): [boolean, string] => {
  // 今回importするproductを持ってくる
  const targetProducts = allProducts.filter((product) =>
    targetProductIds.includes(product.id ?? "")
  );

  // 以下の条件を全て満たすとき確認する
  // a1. checkProducts(確認対象の梱包)が0より多かったら
  // a2. 見積が受注されてるか、見積が案件に一つ以上ある
  // a3. 対象の梱包が一つでも見積もり連携している
  // もしくは
  // b1. checkProducts(確認対象の梱包)が0の場合は、
  // b2. 見積のその他明細行が1つ以上ある
  // a.bどちらも他案件から同期してきた梱包(quoteLineId === null && quoteLineName !== null)の場合はチェック対象外
  const checkProducts = targetProducts.filter(
    (product) => !(product.quoteLineId == null && product.quoteLineName != null)
  );

  // 見積が受注されてるか、見積が案件に一つ以上ある
  const isOrderedOrHasQuotes =
    project?.quoteId !== undefined || quotes.length > 0;

  // 対象の梱包が一つでも見積もり連携している
  const isAnyProductLinked = checkProducts.some(
    (product) => product.quoteLineId !== null
  );

  // ↑の条件確認してチェック実行
  if (checkProducts.length > 0 && isOrderedOrHasQuotes && isAnyProductLinked) {
    // そもそも受注されてなかったらダメ
    if (!project?.quoteId) {
      return [false, InvoiceErrorMessages.CannotImportError];
    }
    // 受注しているquoteが存在しなかったらダメ(これはないはず)
    const quote = quotes.find((q) => q.id === project.quoteId);
    if (!quote) {
      return [false, InvoiceErrorMessages.CannotImportError];
    }
    // 受注したquoteのquoteLineのID
    const orderedQuoteLineIds = quote.quoteLines?.items
      .map((quoteLine) => quoteLine?.id)
      .filter((id) => !!id);
    // チェック対象の連携する見積行のID
    const linkedQuoteLineIds = checkProducts
      .map((product) => product.quoteLineId)
      .filter((id) => !!id);
    // 連携した見積行(linkedQuoteLineIds)が全て受注した見積行(orderedQuoteLineIds)に含まれていればOK
    const isAllLinkedQuoteLineOrdered = linkedQuoteLineIds.every((id) =>
      orderedQuoteLineIds?.includes(id ?? "")
    );
    if (!isAllLinkedQuoteLineOrdered) {
      return [false, InvoiceErrorMessages.CannotImportError];
    }
  } else if (checkProducts.length === 0) {
    if (targetProducts.length > 0) {
      // targetProductsはあるが、checkProductsがないのは、すべて「他案件から同期してきた梱包」と判断
      return [true, ""];
    }

    // そもそも受注されてなかったらダメ
    if (!project?.quoteId) {
      return [false, InvoiceErrorMessages.CannotImportError];
    }
    // 受注しているquoteが存在しなかったらダメ(これはないはず)
    const quote = quotes.find((q) => q.id === project.quoteId);
    if (!quote) {
      return [false, InvoiceErrorMessages.CannotImportError];
    }

    // 受注したquoteのその他のquoteLineのID
    const orderedOtherQuoteLineIds = quote.quoteLines?.items
      // `quoteLine?.showInQuoteSummary`が梱包明細行という判定なので、
      // `!quoteLine?.showInQuoteSummary` が trueなのはその他明細行
      .filter((quoteLine) => !!quoteLine?.id && !quoteLine?.showInQuoteSummary)
      .map((quoteLine) => quoteLine?.id);

    if (
      orderedOtherQuoteLineIds !== undefined &&
      orderedOtherQuoteLineIds.length > 0
    ) {
      return [true, ""];
    } else {
      return [false, InvoiceErrorMessages.EmptyImportTarget];
    }
  }

  return [true, ""];
};

export const convertSelectedProductSummariesToProductCaseInput = (
  targetProductSummaries: GridRowSelectionModel,
  productSummaries: ProductSummary[]
): ProductCaseInput[] => {
  const targetProductCase: ProductCaseInput[] = [];
  for (const t of targetProductSummaries) {
    // idからcase情報持ってくる
    const [productId, order] = t.toString().split("|");

    // productSummariesから対象持ってくる
    const summary = productSummaries.find(
      (p) => p.productId === productId && p.caseOrder?.toString() === order
    );
    if (!summary || !summary.name) continue;

    // 保存
    // すでにproductIdがある場合はcaseNamesに追加、ない場合は新規作成
    const idx = targetProductCase.findIndex((c) => c.productId === productId);
    if (idx === -1) {
      targetProductCase.push({
        productId,
        caseNames: [summary.name],
      });
    } else {
      targetProductCase[idx].caseNames?.push(summary.name);
    }
  }
  return targetProductCase;
};

export function getAmount(
  invoiceLine: InvoiceLine,
  unit: string
): number | null {
  // minimumAmount
  if (invoiceLine.minimumAmount || invoiceLine.minimumAmount === 0)
    return getActualAmount(invoiceLine, unit);
  // If actual amount is greater than minimum amount, return actual amount
  if (
    (getActualAmount(invoiceLine, unit) ?? 0) > (invoiceLine.minimumAmount ?? 0)
  )
    return getActualAmount(invoiceLine, unit);
  // If actual amount is smaller than minimum amount, return case quantity
  return invoiceLine.quantity ?? null;
}

function getActualAmount(
  invoiceLine: InvoiceLine,
  unit: string
): number | null {
  const KG_TON = 1000;
  const ton = (invoiceLine.grossWeight ?? 0) / KG_TON;
  // Extract the amount from special unit
  if (invoiceLine.synced) {
    //PAX-632 連動している請求のみ重量更新
    switch (unit) {
      case "m3":
        return invoiceLine.m3 || invoiceLine.actualAmount || null;
      case "R/T":
        // m3 vs ton
        if (invoiceLine.rt) {
          return invoiceLine.rt;
          //rtが既にある場合はrtを取得
        } else {
          return (
            Math.max(ton, invoiceLine.m3 ?? 0) ||
            invoiceLine.actualAmount ||
            null
          );
        }
      case "PKG":
        return invoiceLine.quantity || invoiceLine.quantity || null;
      default:
        // return quantity or actual amount if units not applicable
        return invoiceLine.quantity || invoiceLine.actualAmount || null;
    }
  } else return invoiceLine.amount || null;
}
