import type { ProjectsByGroupIdForManagerUser, User } from "API";

export type Role = "READONLY" | "ADMIN" | "MANAGER" | "SALES" | "PRODUCTION";

export function getRoleName(role: Role) {
  let roleName = "?";
  switch (role) {
    case "ADMIN":
      roleName = "管理者";
    case "MANAGER":
      roleName = "責任者";
    case "SALES":
      roleName = "営業";
    case "PRODUCTION":
      roleName = "現場";
    case "READONLY":
      roleName = "閲覧";
  }

  return roleName;
}

export function getFullName(
  user: User | ProjectsByGroupIdForManagerUser | null | undefined
) {
  if (!user) return "-";
  if (user.name) return user.name;
  return `${user.lastName} ${user.firstName}`;
}
