import React, { Dispatch, ReactNode, SetStateAction, useState } from "react";
import Link from "next/link";
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import HelpSiteIcon from "@mui/icons-material/QuestionMark";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import MenuIcon from "@mui/icons-material/Menu";
import AccountIcon from "@mui/icons-material/AccountCircle";

import { SUPPORT_SITE_URL } from "utils/constant";
import QrScanner from "components/QrScanner";

interface CustomToolbarProps {
  children?: ReactNode | ReactNode[];
  toggleDrawer?: () => void;
  projectName?: String | undefined;
  title?: String | undefined;
}

/**
 * AppBarの共通アイコン
 * @param children アイコンボタンに追加するReactNode
 * @param toggleDrawer 左ドロワーの状態
 * @param projectName project配下での案件名
 * @param title スケジュール・設定画面のタイトル(画面名・グループ名・テナント名)
 * @returns ボタンのElement
 */
export function AppBarIconButton({
  children,
  toggleDrawer,
  projectName,
  title,
}: CustomToolbarProps): JSX.Element {
  const [showQrScan, setShowQr] = useState(false);
  return (
    <>
      <QrScanner open={showQrScan} setOpen={setShowQr} />
      <AppBar>
        <Toolbar variant={projectName ? "dense" : "regular"}>
          {toggleDrawer && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                ...(title && { marginRight: "1rem" }),
                ...(projectName && { mr: "2", display: { sm: "none" } }),
              }}
            >
              {title && <AccountIcon />}
              {projectName && <MenuIcon />}
            </IconButton>
          )}

          {!projectName && (
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {title}
            </Typography>
          )}

          {projectName && (
            <Box
              sx={{
                flexGrow: 1,
              }}
            >
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{
                  flexGrow: 1,
                  display: { sm: "none" },
                }}
              >
                {projectName}
              </Typography>
            </Box>
          )}

          <Tooltip title="PAXヘルプデスク">
            <Link href={SUPPORT_SITE_URL} target="_blank">
              <IconButton aria-label="pax support site" color="inherit">
                <HelpSiteIcon />
              </IconButton>
            </Link>
          </Tooltip>

          {children}

          <Tooltip title="QRコードスキャン">
            <IconButton
              aria-label="scan QR code"
              color="inherit"
              onClick={() => setShowQr(true)}
            >
              <QrCodeScannerIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
    </>
  );
}
