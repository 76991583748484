import { API } from "aws-amplify";

import { isProductCaseKey } from "./image";
import { CustomError } from "./error";

import { GraphQLInput } from "hooks/data";
import { Project } from "API";
import * as mutations from "graphql/mutations";

export function getProjectTypeName(
  type: "PACK" | "OTHER" | string | null | undefined
) {
  switch (type) {
    case "PACK":
      return "梱包";
    case "OTHER":
      return "その他";
    default:
      return "梱包";
  }
}

export enum RedirectTypes {
  schedules = "schedules",
}

/**
 * 案件の公開している画像に対象の梱包のものが含まれるか確認する
 * @param project 案件
 * @param productId 梱包ID
 * @returns 梱包の画像が含まれる場合はtrue
 */
export function isSharedProduct(project: Project, productId: string) {
  return (
    project &&
    project.externalSharedImageKeys &&
    project.externalSharedImageKeys.some(
      (key) => key && isProductCaseKey(key, productId)
    )
  );
}

export const updateProjectWithOptimisticLock = async (input: GraphQLInput) => {
  try {
    const res = await API.graphql({
      query: mutations.updateProjectWithOptimisticLock,
      variables: {
        input,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    if (res.errors) {
      throw new Error(res.errors);
    }

    return res.data.updateProjectWithOptimisticLock;
  } catch (err) {
    throw new CustomError(err, "update");
  }
};
