import { useState, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

import { useAccounts } from "contexts/accounts";
import { useDebounce } from "hooks/debounce";

interface Selected {
  name: string | null;
  id?: string | null;
}

export default function Account() {
  const [val, setVal] = useState<string>("");
  const [selected, setSelected] = useState<Selected | null>(null);
  const { control, setValue, watch } = useFormContext();
  const { accounts, loading, refetch } = useAccounts();
  const debouncedInputText = useDebounce(val, 500);

  const [options, setOptions] = useState<any[]>([]);

  const accountName = watch("accountName");
  const accountId = watch("accountId");

  useEffect(() => {
    setOptions(
      accounts
        .filter((account) => account.active ?? true)
        .map((acc, idx) => {
          const contacts = acc.contacts?.items.filter(
            (contact) => contact?.active ?? true
          );
          return {
            name: acc.name,
            site: acc.site,
            numOfContacts: contacts?.length || 0,
            id: acc.id,
            key: acc.id + idx,
          };
        })
    );
  }, [accounts]);

  useEffect(() => {
    if (!accountName) return;
    setSelected({ name: accountName, id: accountId });
  }, [accountName, accountId]);

  const reload = async (keyword: string) => {
    await refetch({
      filter: {
        name: {
          contains: keyword,
        },
      },
      limit: 9999,
    });
  };

  useEffect(() => {
    if (debouncedInputText) {
      reload(debouncedInputText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInputText]);

  return (
    <Autocomplete
      includeInputInList
      filterSelectedOptions
      isOptionEqualToValue={(option, value) =>
        !!value.id && option.id === value.id
      }
      getOptionLabel={(opt) => opt.name || ""}
      options={options}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          {option.name} {option.site} ({option.numOfContacts})
        </Box>
      )}
      inputValue={val}
      value={selected}
      onInputChange={(_, value) => {
        setVal(value || "");
        setSelected({ name: value });
      }}
      onChange={(_, value) => {
        if (value) {
          setValue("accountId", value.id);
          setValue("accountName", `${value.name} ${value.site || ""}`);
          (document.activeElement as HTMLInputElement).blur();
        } else {
          setValue("accountId", null);
          setValue("accountName", null);
          setSelected({ name: null, id: null });
        }
      }}
      loading={loading}
      renderInput={(params) => (
        <Controller
          render={({ field }) => (
            <TextField
              sx={{ width: "100%" }}
              label="取引先"
              variant="outlined"
              required
              {...field}
              {...params}
              onFocus={() => {
                setVal("");
                setValue("accountId", null);
                setValue("accountName", null);
                setSelected({ name: "", id: null });
              }}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password",
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          control={control}
          name="accountId"
          rules={{
            required: "必須項目です",
          }}
        />
      )}
    />
  );
}
