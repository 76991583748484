import { useRouter } from "next/router";
import IconButton from "@mui/material/IconButton";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import Tooltip from "@mui/material/Tooltip";

import { useAuth } from "contexts/auth";
import { AppBarIconButton } from "layouts/AppBar/AppBarIconButton";

interface AppBarProps {
  title: string;
  open?: boolean;
  toggleDrawer: () => void;
}

export function AdminAppBar({ title, toggleDrawer }: AppBarProps) {
  const { currentGroup } = useAuth();
  const router = useRouter();

  if (!currentGroup)
    return (
      <div className="h-screen flex justify-center items-center">
        グループに所属されていません
      </div>
    );

  return (
    <>
      <AppBarIconButton toggleDrawer={toggleDrawer} title={title}>
        <Tooltip title="案件管理">
          <IconButton
            aria-label="go to projects"
            color="inherit"
            onClick={() => router.push("/")}
          >
            <AccountTreeIcon />
          </IconButton>
        </Tooltip>
      </AppBarIconButton>
    </>
  );
}
